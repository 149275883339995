.container-nav {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.navbar .container-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.hour {
    border: none;
    border-radius: 0 !important;
    display: inline-block;
    float: left;
    padding: 10px 0;
    text-align: center;
    background-color: #fff;
}
.hour_active {
        background-color: #bdbdbd;
    }

.hour:hover{
    background-color: #f5f5f5;
}
@media (min-width:100px) {
    .hour {
        width: 100%;
    }
}

@media (min-width:200px) {
    .hour {
        width: 50%;
    }
}

@media (min-width:300px) {
    .hour {
        width: 33.333333%;
    }
}

.plageLibre {
    flex-direction: row;
    padding: 0;
}

.plageLibreDeck {
    border-radius: 0.25rem;
    margin: 0;
}


.titleAgendaSite {
    background-color: rgb(248 249 250);
    
    border-bottom: solid 1px rgba(0,0,0,0.125);
    border-top: solid 1px rgba(0,0,0,0.125);
}

.SousTitleAgendaSite {
    margin: 15px;
}

.SousSousTitleAgendaSite {
    float:right;
}

.rdv {
    margin-bottom: 15px !important;
}


    .rdv > div.card-body {
        padding: 0;
    }

.cancelRDV {
    margin: 0 0 10px 10px;
}

.hide {
    display: none !important;
}

.navbar-nav li {
    padding: 0 5px;
}

hr.redCHRH {
    background-color: #9C0000;
    height: 2px;
    width: auto;
}

/* Loading */
#updateProgress {
    z-index: 99;
}

#overlay {
    position: fixed;
    z-index: 99;
    top: 0px;
    left: 0px;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    filter: opacity(90%);
    opacity: 0.9;
    -moz-opacity: 0.9;
}

#modalprogress {
    position: absolute;
    top: 40%;
    left: 50%;
    margin: -11px 0 0 -60px;
    font-weight: bold;
    font-size: 14px;
}

    #modalprogress > img {
        display: block;
        margin: 0 auto;
    }


.content {
    height: 150px;
    padding-top: 75px;
    text-align: center;
    background-color: #F9F9F9;
    font-size: 48px;
}

