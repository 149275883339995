#Log-in-with-itsme {
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-size: 14px;
    font-weight: bold;
}
@media (max-width: 900px)
{
    #Log-in-with-itsme {
        display: none;
    }
}